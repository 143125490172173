import experimentFactory from 'santa-main-r/lib/lib/common/experimentFactory.js'
import layoutUtils from 'carmi-host-extensions/src/layout/layoutUtils'

// remember to mark these libs as externals in bolt main webpack.config.js
const init = () => {
    const experimentInst = experimentFactory.build(window)
    window.define('experiment', [], () => experimentInst)
    window.define('layout-utils', [], () => layoutUtils)
}

export {init}
